export const COMMON_CONSTANT = {
  COMPLETED: 1,
  IN_PROCESS: 2,
  PENDING: 3,
  NOT_COMPLETED: 4,
  APPROVE: 5,
  REJECT: 6,
  DOCUMENT_PROCESS_END: 7,
  REPLY_TO_RECEIVED_DOCUMENT: 8,
  EXECUTE_AND_MESSAGE_SENDING: 9,
  PROCESS_START: 10,
  SENT: 11,
  PROCESS_STATUS: 12,
  SCHEDULE: 13,
  DRAFT: 14,
};

export const DOCUMENT_LINK_TYPE = {
  TO_PERFORM: 1,
  TO_CHASE: 2,
  IN_REPLY: 3,
};

export const DOCUMENT_TYPE = {
  TO_CHASE: 2,
  IN_REPLY: 3,
};

export const ACTION_REQUEST = {
  NEW_INTERNET_CONNECTION: 1,
  INTERNET_PROBLEM: 26, // 2
  INTERNET_VIOLATION_FORM: 3,
  HARDWARE_REPAIR_FORM: 4,
  HARDWARE_PROBLEM: 29, //5
  ITGOODS_FORM: 6,
  SOFTWARE_INSTALLATION: 7, // 7
  SOFTWARE_PROBLEM: 8,
  NEW_EMAIL: 9,
  EMAIL_PROBLEM: 10,
  IMS_PHONE: 11,
  SYSTEM_MAINTENANCE: 13,
  MONITOR_REQUEST: 20,
};

export const currency = {
  USD: 1,
  AFN: 2,
};

export const throthleDelaySeconds = {
  delay: 2000,
  deBounce: 1000,
};
